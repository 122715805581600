import { get, post } from './http'

export const getIndexApp = () => {
  const url = '/message/index_app'
  return get(url)
}

export const getSupermanRemind = (params) => {
  const url = '/message/get_superman_remind'
  return get(url, params)
}

export const getOrderMessage = (params) => {
  const url = '/message/get_order_messages'
  return get(url, params)
}

export const setMessageRead = (data) => {
  const url = '/message/set_readed'
  return post(url, data)
}

export const deleteMssage = (data) => {
  const url = '/message/delete'
  return post(url, data)
}
